import axios from 'axios';

export const fetchPortalData = async () => {
  try {
    const response = await axios.get(`/api/v2/portals/strategic_planning`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos del portal:', error);
    throw error;
  }
};
