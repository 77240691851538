import React, { useEffect, useState } from 'react';
import { fetchPortalData } from '../Api/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faLightbulb, faChartSimple, faChevronDown, faHeart, faShield, faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from '../../LoadingSpinner';

const View = () => {
  const [portalData, setPortalData] = useState(null);
  const [error, setError] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isAccordionOpenTwo, setIsAccordionOpenTwo] = useState(false);
  const [isAccordionOpenThree, setIsAccordionOpenThree] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchPortalData();
        setPortalData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getData();
  }, []);

  if (error) return <p>Error: {error}</p>;
  if (!portalData) return <LoadingSpinner />;

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const toggleAccordionTwo = () => {
    setIsAccordionOpenTwo(!isAccordionOpenTwo)
  }

  const toggleAccordionThree = () => {
    setIsAccordionOpenThree(!isAccordionOpenThree)
  }

  return (
    <div className='body-view'>

      {/*Mision y Vision*/}
      <div className='card-mision-and-vision'>
        <div className='card-mision'>
          <div className='header-card'>
            <FontAwesomeIcon icon={faBullseye} className="custom-icon" />
            <h3 className='font-weight-bold mt-1'>Mision</h3>
          </div>
          <p>{portalData.mision}</p>
        </div>
        <div className='card-vision'>
          <div className='header-card'>
            <FontAwesomeIcon icon={faLightbulb} className="custom-icon" />
            <h3 className='font-weight-bold mt-1'>Vision</h3>
          </div>
          <p>{portalData.vision}</p>
        </div>
      </div>

      {/*Objetivos*/}
      <div className='card-objetive'>
        <div className='header-card'>
          <FontAwesomeIcon icon={faCrosshairs} className="custom-icon" />
          <h3 className='font-weight-bold mt-1'>Objetivos</h3>
        </div>
        <div>
          {portalData.quality_objectives && portalData.quality_objectives.length > 0 ? (
            portalData.quality_objectives.map((objetives, index) => (
              <p key={index}>{index+1}. {objetives.name} - {objetives.weight}%</p>
            ))
          ) : (
            <p>No hay objetivos disponibles.</p>
          )}
        </div>
      </div>

      {/* Acordeón Estrategias*/}
      <div className="accordion">
        <div className="accordion-title" onClick={toggleAccordion}>
          <div className='header-accordion'>
            <div className='header-card-accordion'>
              <FontAwesomeIcon icon={faChartSimple} className="custom-icon" />
              <p className='mt-3'>Estrategias</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '12px' }} />
            </div>
          </div>
          
        </div>
        {isAccordionOpen && (
          <div className="accordion-content">
            {portalData.strategies && portalData.strategies.length > 0 ? (
              portalData.strategies.map((estrategia, index) => (
                <li key={index}>{estrategia.name}</li>
              ))
            ) : (
              <p>No hay estrategias disponibles.</p>
            )}
          </div>
        )}
      </div>

      {/* Acordeón Valores Corporativos*/}
      <div className="accordion">
        <div className="accordion-title" onClick={toggleAccordionTwo}>
          <div className='header-accordion'>
            <div className='header-card-accordion'>
              <FontAwesomeIcon icon={faHeart} className="custom-icon" />
              <p className='mt-3'>Valores Corporativos</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '12px' }} />
            </div>
          </div>
          
        </div>
        {isAccordionOpenTwo && (
          <div className="accordion-content">
            {portalData.corporate_values && portalData.corporate_values.length > 0 ? (
              portalData.corporate_values.map((estrategia, index) => (
                <li key={index}>{estrategia.name}</li>
              ))
            ) : (
              <p>No hay estrategias disponibles.</p>
            )}
          </div>
        )}
      </div>

      {/* Acordeón Politicas*/}
      <div className="accordion">
        <div className="accordion-title" onClick={toggleAccordionThree}>
          <div className='header-accordion'>
            <div className='header-card-accordion'>
              <FontAwesomeIcon icon={faShield} className="custom-icon" />
              <p className='mt-3'>Politicas</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '12px' }} />
            </div>
          </div>
          
        </div>
        {isAccordionOpenThree && (
          <div className="accordion-content">
            {portalData.quality_politics && portalData.quality_politics.length > 0 ? (
              <p>{portalData.quality_politics}</p> 
            ) : (
              <p>No hay estrategias disponibles.</p>
            )}
          </div>
        )}
      </div>

      <div className='mt-5'>
        {/*Card Mapa de Procesos*/}
        <div className='img-card'>
          <div className='title-card-img'>
            <p>Mapa de Procesos</p>
          </div>
          <img src={portalData.img_uno} alt="Imagen mapa de procesos" />
          <div className='button-card-img'>
            <a href={portalData.img_uno} target='blank'>{portalData.filename_uno}</a>
          </div>
        </div>
        <div className='mt-5'></div>

        {/*Card Organigrama*/}
        <div className='img-card'>
          <div className='title-card-img'>
            <p>Organigrama</p>
          </div>
          <img src={portalData.img_dos} alt="Imagen Organigrama" />
          <div className='button-card-img'>
            <a href={portalData.img_dos} target='blank'>{portalData.filename_dos}</a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default View;
