import React from 'react'
import View from './Components/View';

const App = () => {
  return (
    <div>
      <View/>
    </div>
  )
}

export default App