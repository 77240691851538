import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["discardButton", "acceptButton", "addButtons"];

  connect() {
    this.formChanged = false;
    this.element.addEventListener("input", this.detectChange.bind(this));
    this.element.addEventListener("change", this.detectChange.bind(this));
  }

  detectChange() {
    if (!this.formChanged) {
      this.formChanged = true;
      this.toggleButtons();
    }
  }

  discardChanges(event) {
    event.preventDefault();
    this.element.reset(); // Restaura los valores originales del formulario
    this.formChanged = false;
    this.toggleButtons();
  }

  toggleButtons() {
    if (this.formChanged) {
      this.discardButtonTargets.forEach(button => button.classList.remove("d-none"));
      this.acceptButtonTargets.forEach(button => button.classList.remove("d-none"));
      this.addButtonsTargets.forEach(button => button.classList.add("d-none"));
    } else {
      this.addButtonsTargets.forEach(button => button.classList.remove("d-none"));
      this.discardButtonTargets.forEach(button => button.classList.add("d-none"));
      this.acceptButtonTargets.forEach(button => button.classList.add("d-none"));
    }
  }
}